import React from 'react';
import Header from '../components/header';
import { Grid, makeStyles } from '@material-ui/core';
import { LinedHeader } from '../components/styledComponents';
import Footer from '../components/footer';
import ThemeCollection from '../components/ThemeCollection';

const useStyles = makeStyles((theme) => ({
  treats: {
    padding: '0 60px',
    [theme.breakpoints.down('sm')]: {
      padding: '0'
    }
  }
}));

const Cookies = () => {
  const classes = useStyles();

  return (
    <div>
      <Header title="Cookies" />
      <Grid>
        <Grid item xs={12} justify="center" className={classes.treats}>
          <LinedHeader>Cookies</LinedHeader>
            {/* <StyledLink to="/menu">Click here to see our menu & pricing</StyledLink> */}

          <ThemeCollection
            theme_name="Valentine's Day"
            type="cookies"
            items={[
              "vday10",
              "vday12",
              "vday11",
              "vday14",
              "vday13",
              "vday2",
              "vday3",
              "vday9",
              "vday4",
              "vday6",
              "vday7",
            ]}
          />
          <ThemeCollection
            theme_name="Birthday"
            type="cookies"
            items={[
              "birthdaytheme5",
              "birthdaytheme3",
              "birthday5",
              "birthday3",
              "birthdaytheme1",
            ]}
          />

          <ThemeCollection
            theme_name="Wedding"
            type="cookies"
            items={[
              "western1",
              "wedding1",
              "western2",
              "wedding10",
              "western4",
              "wedding15",
              "western5",
              "wedding9",
              "western6",
              "wedding14",
              "wedding2",
              "wedding3",
              "wedding8",
              "wedding11",
              "wedding12",
              "wedding13",
              "wedding16",
              "wedding17",
              "wedding18",
              "wedding4",
              "wedding5",
              "wedding6",
              "wedding7",
            ]}
          />
          <ThemeCollection
            theme_name="Writing"
            type="cookies"
            items={[
              "lettering4",
              "lettering14",
              "lettering11",
              "lettering12",
              "lettering13",
              "lettering2",
              "lettering1",
              "lettering8",
              "lettering3",
              "lettering6",
              "lettering7",
              "lettering9",
              "lettering10",
            ]}
          />

          <ThemeCollection
            theme_name="Henna"
            type="cookies"
            items={[
              "henna4",
              "henna11",
              "henna20",
              "henna13",
              "henna19",
              "henna6",
              "henna15",
              "henna7",
              "henna12",
              "henna8",
              "henna17",
              "henna9",
              "henna5",
              "henna10",
              "henna1",
              "henna3",

            ]}
          />

          <ThemeCollection
            theme_name="Fruits & Flowers"
            type="cookies"
            items={[
              "cherryblossom1",
              "strawberry1",
              "cherryblossom2",
              "cherryblossom5",
              "cherryblossom6",
              "strawberry2"
            ]}
          />

          <ThemeCollection
            theme_name="Baby Shower"
            type="cookies"
            items={[
              "babyshower1",
              "babyshower2",
              "babyshower3",
              "babyshower4",
              "babyshower6",
              "babyshower7",
              "babyshower8",
              "babyshower9",
              "babyshower10",
              "babyshower11",
              "babyshower12",
              "babyshower13",
              "babyshower14",
              "babyshower17",
            ]}
          />

          <ThemeCollection
            theme_name="Custom"
            type="cookies"
            items={[
              "cocomelon1",
              "cocomelon2",
              "cocomelon3",
              "custom1",
              "custom2",
              "custom3",
              "custom4",
              "custom5",
              "custom6",
              "custom7",
              "custom8",
              "custom9",
              "custom10",
              "dino1",
              "dino2",
              "disney1",
              "disney2",
              "teacher1",
              "teacher2",
              "teacher3",
              "teacher4",
              "momday1",
            ]}
          />

          <ThemeCollection
            theme_name="Sports"
            type="cookies"
            items={[
              "baseball4",
              "baseball1",
              "baseball7",
              "soccer2",
              "baseball5",
              "baseball6",
              "soccer3"
            ]}
          />
          <ThemeCollection
            theme_name="Holiday"
            type="cookies"
            items={[
              "thanksgiving1",
              "halloween1",
              "christmas11",
              "christmas1",
              "christmas13",
              "christmas3",
              "christmas5",
              "christmas6",
              "christmas7",
              "christmas9",
              "christmas12",
              "christmas14",
              "christmas15",
              "christmas16",
              "christmasbox2",
            ]}
          />
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
};

export default Cookies;
