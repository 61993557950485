import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { LinedHeader } from './styledComponents';
import styled from 'styled-components'
import Masonry from 'react-masonry-css'

const useStyles = makeStyles((theme) => ({
  treats: {
    padding: '0 60px',
    [theme.breakpoints.down('sm')]: {
      padding: '0'
    }
  }
}));

const Image = styled.img`
  border: 2px solid black;
  max-width: 100%;
`

const breakpointColumnsObj = {
  default: 4,
  1100: 3,
  700: 2
};

const ThemeCollection = (props) => {
  const classes = useStyles();

  return (
    <div>
      <Grid>
        <Grid item xs={12} justify="center" className={classes.treats}>
        <h2>{props.theme_name}</h2>
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column">
            {
              props.items.map((item) => {
                return(
                  <Image src={require(`../images/${props.type}/${item}.jpg`).default}/>
                )
              })
            }
          </Masonry>
        </Grid>
      </Grid>
    </div>
  );
};

export default ThemeCollection;
